// src/Congrats.js

import React from "react";
import { useNavigate } from "react-router-dom";
import Confetti from "react-confetti";
import "./Congrats.css";

const Congrats = () => {
  const navigate = useNavigate();

  const handleReturn = () => {
    navigate("/");
  };

  return (
    <div className="congrats-container">
      <Confetti />
      <div className="congrats-content">
        <h1>🎉 Congratulations! 🎉</h1>
        <p>
          We will use the most advanced algorithm to ensure that you are paired with just the right person! Thank you for participating in the Big Little Pairing for the AB Duke Scholars.
        </p>
        <button className="return-button" onClick={handleReturn}>
          Return to Home
        </button>
      </div>
    </div>
  );
};

export default Congrats;
