// src/Form.js

// there is NEXT - server side componenets - server side good for seo and speed - server side rendering - next.js

import React, { useState, useEffect, useRef } from "react";
import { db } from "./firebase";
import { collection, addDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import "./Form.css";

const Form = () => {
  const navigate = useNavigate();

  // Define form steps
  const [step, setStep] = useState(1);

  // Introductory Information State
  const [introInfo, setIntroInfo] = useState({
    name: "",
  });

  // Basic Information State
  const [basicInfo, setBasicInfo] = useState({
    origin: "",
    field: "",
    classYear: "",
  });

  // Writing Questions State
  const writingQuestions = [
    {
      id: 1,
      question: "What do you think are the defining problems of our generation?",
      answer: "",
    },
    {
      id: 2,
      question: "What are some non-traditional things you did growing up?",
      answer: "",
    },
    {
      id: 3,
      question: "Where are you an outlier?",
      answer: "",
    },
    {
      id: 4,
      question: "What do you do purely for the joy it?",
      answer: "",
    },
  ];

  const [currentWritingQuestion, setCurrentWritingQuestion] = useState(0);
  const [answers, setAnswers] = useState(writingQuestions);

  // Timer and Word Count
  const [wordCount, setWordCount] = useState(0);
  const [timeLeft, setTimeLeft] = useState(420); // 7 minutes in seconds
  const timerRef = useRef(null);
  const typingTimeoutRef = useRef(null);
  const blurTimeoutRef = useRef(null);

  // Blur and Progress Loss
  const [isBlurred, setIsBlurred] = useState(false);
  const [progressLost, setProgressLost] = useState(false);

  // Alert Message State
  const [alert, setAlert] = useState({ message: "", type: "" });

  // Handle Intro Info Change
  const handleIntroChange = (e) => {
    const { name, value } = e.target;
    setIntroInfo({ ...introInfo, [name]: value });
  };

  // Handle Basic Info Change
  const handleBasicChange = (e) => {
    const { name, value } = e.target;
    setBasicInfo({ ...basicInfo, [name]: value });
  };

  // Handle Writing Answer Change
  const handleAnswerChange = (e) => {
    const updatedAnswers = [...answers];
    updatedAnswers[currentWritingQuestion].answer = e.target.value;
    setAnswers(updatedAnswers);

    // Update word count
    const words = e.target.value.trim().split(/\s+/).filter(word => word).length;
    setWordCount(words);

    // Clear existing timers
    if (typingTimeoutRef.current) clearTimeout(typingTimeoutRef.current);
    if (blurTimeoutRef.current) clearTimeout(blurTimeoutRef.current);

    setIsBlurred(false); // Reset blur
    setProgressLost(false); // Reset progress loss
    setAlert({ message: "", type: "" }); // Clear alerts

    // First timeout: show "Keep typing" after 3 seconds
    typingTimeoutRef.current = setTimeout(() => {
      setAlert({ message: "Keep typing! Don't overthink it, or you might lose your progress.", type: "info" });
      setIsBlurred(true);

      // Second timeout: reset progress after additional 7 seconds
      blurTimeoutRef.current = setTimeout(() => {
        const updated = [...answers];
        updated[currentWritingQuestion].answer = "";
        setAnswers(updated);
        setWordCount(0);
        setIsBlurred(false);
        setProgressLost(false);
        setAlert({ message: "Your progress for this question has been lost.", type: "error" });
        
        // Reset the timer to 7 minutes
        setTimeLeft(420);
        if (timerRef.current) clearInterval(timerRef.current);
        timerRef.current = setInterval(() => {
            setTimeLeft((prev) => {
                if (prev <= 1) {
                    clearInterval(timerRef.current);
                    timerRef.current = null;
                    handleTimerEnd();
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);
      }, 7000); // 7 seconds
    }, 3000); // 3 seconds
  };

  // Handle Timer End (7 minutes)
  const handleTimerEnd = () => {
    // Automatically proceed to the next question
    handleNextWriting();
  };

  // Initialize timer function
  const initializeTimer = () => {
    if (timerRef.current) clearInterval(timerRef.current);
    setTimeLeft(420);
    timerRef.current = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          clearInterval(timerRef.current);
          timerRef.current = null;
          handleTimerEnd();
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  // Reset Form
  const resetForm = () => {
    setStep(1);
    setIntroInfo({
      name: "",
    });
    setBasicInfo({
      origin: "",
      field: "",
      classYear: "",
    });
    setAnswers(writingQuestions);
    setCurrentWritingQuestion(0);
    setWordCount(0);
    setTimeLeft(420); // 7 minutes
    setIsBlurred(false);
    setProgressLost(false);
    setAlert({ message: "", type: "" });
    if (timerRef.current) clearInterval(timerRef.current);
    if (typingTimeoutRef.current) clearTimeout(typingTimeoutRef.current);
    if (blurTimeoutRef.current) clearTimeout(blurTimeoutRef.current);
  };

  // Handle Intro Submit
  const handleIntroSubmit = (e) => {
    e.preventDefault();
    if (introInfo.name.trim() === "") {
      alertMessage("Please enter your name.", "error");
      return;
    }
    setStep(2);
  };

  // Handle Basic Info Submit
  const handleBasicSubmit = (e) => {
    e.preventDefault();
    setStep(3);
    // Initialize the timer
    initializeTimer();
  };

  // Handle Writing Submit
  const handleWritingSubmit = async () => {
    // Submit data to Firebase
    try {
      await addDoc(collection(db, "responses"), {
        introInfo,
        basicInfo,
        answers,
        timestamp: new Date(),
      });
      navigate("/congrats");
    } catch (error) {
      console.error("Error adding document: ", error);
      alertMessage("There was an error submitting your responses.", "error");
    }
  };

  // Handle Next Writing Question
  const handleNextWriting = () => {
    // Clear existing timers
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
      typingTimeoutRef.current = null;
    }
    if (blurTimeoutRef.current) {
      clearTimeout(blurTimeoutRef.current);
      blurTimeoutRef.current = null;
    }

    if (currentWritingQuestion < answers.length - 1) {
      setCurrentWritingQuestion(currentWritingQuestion + 1);
      setWordCount(0);
      setTimeLeft(420);
      // Initialize timer for next question
      initializeTimer();
    } else {
      handleWritingSubmit();
    }
  };

  // Handle Canceling Progress Loss
  const handleCancelProgressLoss = () => {
    setIsBlurred(false);
    setProgressLost(false);
    if (typingTimeoutRef.current) clearTimeout(typingTimeoutRef.current);
  };

  // Alert Message Handler
  const alertMessage = (msg, type) => {
    setAlert({ message: msg, type });
    setTimeout(() => {
      setAlert({ message: "", type: "" });
    }, 5000);
  };

  // Cleanup timers on unmount and reinitialize on question change
  useEffect(() => {
    if (step === 3) {
      initializeTimer();
    }
    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
      if (typingTimeoutRef.current) clearTimeout(typingTimeoutRef.current);
      if (blurTimeoutRef.current) clearTimeout(blurTimeoutRef.current);
    };
  }, [step, currentWritingQuestion]);

  return (
    <div className="form-container">
      {alert.message && (
        <div class={`alert ${alert.type}`}>
          {alert.message}
        </div>
      )}
      {step === 1 && (
        <form onSubmit={handleIntroSubmit} className="form-step">
          <h2>Welcome to the Big Little Pairing</h2>
          <p className="intro-info">
            This form is for the Big Little Pairing for the AB Duke Scholars. We will use your responses to pair you with someone who complements you the most! Your privacy is our top priority. We won't read any of your responses! Be as honest as you can be :D
          </p>
          <p className="intro-message">
            P.S. We want to hear your <i>real</i> thoughts here—no right or wrong answers (except your name and location, lol, those gotta be accurate). Just a heads-up: once you start typing, keep going! If you stop, you might lose your progress, so phrases like “Umm, let me think” or “Wait, maybe this is better” are totally cool. Be your authentic self! 
          </p>
          <label>
            What is your name?
            <input
              type="text"
              name="name"
              value={introInfo.name}
              onChange={handleIntroChange}
              required
            />
          </label>
          <button type="submit" className="button">Start</button>
        </form>
      )}

      {step === 2 && (
        <form onSubmit={handleBasicSubmit} className="form-step">
          <h2>Basic Information</h2>
          <label>
            Where are you from?
            <input
              type="text"
              name="origin"
              value={basicInfo.origin}
              onChange={handleBasicChange}
              required
            />
          </label>
          <label>
            Are you STEM or Humanities?
            <select
              name="field"
              value={basicInfo.field}
              onChange={handleBasicChange}
              required
            >
              <option value="">Select</option>
              <option value="STEM">STEM</option>
              <option value="Humanities">Humanities</option>
            </select>
          </label>
          <label>
            Are you class of 27 or class of 28?
            <select
              name="classYear"
              value={basicInfo.classYear}
              onChange={handleBasicChange}
              required
            >
              <option value="">Select</option>
              <option value="Class of 27">Class of 27</option>
              <option value="Class of 28">Class of 28</option>
            </select>
          </label>
          <button type="submit" className="button">Next</button>
        </form>
      )}

      {step === 3 && (
        <div className="form-step">
          <h2>{answers[currentWritingQuestion].question}</h2>
          <textarea
            className={`answer-textarea ${isBlurred ? "blurred" : ""} ${progressLost ? "progress-lost" : ""}`}
            value={answers[currentWritingQuestion].answer}
            onChange={handleAnswerChange}
            placeholder="Start typing your answer here..."
          ></textarea>
          <div className="info-bar">
            <span className="word-count">
              Words: {wordCount} / 150
            </span>
            <span className="time-left">
              Time Left: {Math.floor(timeLeft / 60)}:
              {("0" + (timeLeft % 60)).slice(-2)}
            </span>
          </div>
          <button
            onClick={handleNextWriting}
            disabled={wordCount < 150}
            className="next-button"
          >
            {currentWritingQuestion < answers.length - 1 ? "Next" : "Submit"}
          </button>
          {progressLost && (
            <div className="progress-loss-overlay">
              <p>Your progress for this question has been lost.</p>
              <button onClick={handleCancelProgressLoss} className="cancel-button">OK</button>
            </div>
          )}
          {!isBlurred && alert.type === "info" && (
            <p className="gentle-reminder">Keep typing - don't overthink it!</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Form;
