// src/firebase.js

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "YOUR_API_KEY", // Replace with your actual API key
  authDomain: "pretty-form-b600e.firebaseapp.com",
  projectId: "pretty-form-b600e",
  storageBucket: "pretty-form-b600e.appspot.com",
  messagingSenderId: "504743064072",
  appId: "1:504743064072:web:64c70e837944b81e3d17bd",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };